import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';

import { AuthContext } from '@/contexts/AuthContext';
import { NEXT_PUBLIC_AUTH0_CLIENT_ID } from '@/env';

export function useAuth() {
  const auth0 = useAuth0();
  const { authModel, update } = useContext(AuthContext);

  const { isLoading, error } = auth0;

  const logout = () => {
    return auth0.logout({
      client_id: NEXT_PUBLIC_AUTH0_CLIENT_ID,
      returnTo: global?.window?.location?.origin,
    });
  };

  const loginWithRedirect = () => {
    return auth0.loginWithRedirect({
      redirectUri: global?.window?.location?.origin,
      appState: {
        callbackUri: global?.window?.location?.href,
      },
    });
  };

  return {
    logout: logout,
    loginWithRedirect: loginWithRedirect,
    isLoading,
    error,
    authModel,
    updateAuthModel: update,
  };
}
