import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import back from './assets/back.svg';
import home from './assets/home.svg';
import logo from './assets/logo.svg';
import magnifier from './assets/magnifier.svg';
import ticket from './assets/ticket.svg';
import user from './assets/user.svg';

import { useAuth } from '@/hooks/useAuth';
import useViewport from '@/hooks/useViewport';

export type HeaderType = 'static' | 'float' | 'invisible';

export type HeaderProps = {
  type: HeaderType;
};

const MobileStaticHeader = (props: HeaderProps) => {
  const {} = props;
  const { authModel } = useAuth();

  return (
    <div className="relative">
      <div className={`flex w-full items-center justify-between p-[27px]`}>
        <Link href={`/`}>
          <a className={`contents`}>
            <Image width={109} height={32} src={logo} alt="logo" />
          </a>
        </Link>

        <div className={`flex items-center gap-[20px]`}>
          <Link href={`/search`}>
            <a>
              <div className={`p-[5px]`}>
                <div className={`h-[24px]`}>
                  <Image
                    width={24}
                    height={24}
                    src={magnifier}
                    alt="magnifier"
                  />
                </div>
              </div>
            </a>
          </Link>

          {!authModel.auth0user && (
            <Link href={`/my-profile`}>
              <a className={`contents`}>
                <div className={`p-[5px]`}>
                  <div className={`h-[24px]`}>
                    <Image
                      width={24}
                      height={24}
                      src={user}
                      alt="user"
                      className={``}
                    />
                  </div>
                </div>
              </a>
            </Link>
          )}
          {authModel.auth0user && (
            <>
              <Link href={`/my-purchases`}>
                <a className={`contents`}>
                  <div className={`p-[5px]`}>
                    <div className={`h-[24px]`}>
                      <Image
                        width={24}
                        height={24}
                        src={ticket}
                        alt="user"
                        className={``}
                      />
                    </div>
                  </div>
                </a>
              </Link>
              <Link href={`/my-profile`}>
                <a className={`contents`}>
                  <div className={`p-[5px]`}>
                    <div className={`h-[24px]`}>
                      <Image
                        width={24}
                        height={24}
                        src={user}
                        alt="user"
                        className={``}
                      />
                    </div>
                  </div>
                </a>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const MobileFloatHeader = (props: HeaderProps) => {
  const {} = props;
  const router = useRouter();

  return (
    <div className="relative">
      <div
        className={`absolute top-0 z-[-1] h-[120px] w-full`}
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(28, 25, 25, 0) 100%)`,
        }}
      />
      <div
        className={`absolute flex w-full items-center justify-between p-[27px]`}
      >
        <button onClick={() => router.back()}>
          <div className={`p-[5px]`}>
            <div className={`h-[18px]`}>
              <Image width={10} height={18} src={back} alt="back" />
            </div>
          </div>
        </button>

        <div className={`flex items-center gap-[20px]`}>
          <Link href={`/search`}>
            <a className={`contents`}>
              <div className={`p-[5px]`}>
                <div className={`h-[24px]`}>
                  <Image
                    width={24}
                    height={24}
                    src={magnifier}
                    alt="magnifier"
                  />
                </div>
              </div>
            </a>
          </Link>
          <Link href={`/`}>
            <a className={`contents`}>
              <div className={`p-[5px]`}>
                <div className={`h-[24px]`}>
                  <Image width={24} height={24} src={home} alt="home" />
                </div>
              </div>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const TabletFloatHeader = (props: HeaderProps) => {
  const {} = props;

  return (
    <div className="relative">
      <div
        className={`absolute top-0 z-[-1] h-[120px] w-full`}
        style={{
          background: `linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(28, 25, 25, 0) 100%)`,
        }}
      />
      <div
        className={`absolute flex w-full items-center justify-between p-[27px]`}
      >
        <Link href={`/`}>
          <a className={`contents`}>
            <Image width={109} height={32} src={logo} alt="logo" />
          </a>
        </Link>

        <div className={`flex items-center gap-[20px]`}>
          <Link href={`/search`}>
            <a className={`contents`}>
              <div className={`p-[5px]`}>
                <div className={`h-[24px]`}>
                  <Image
                    width={24}
                    height={24}
                    src={magnifier}
                    alt="magnifier"
                  />
                </div>
              </div>
            </a>
          </Link>
          <Link href={`/`}>
            <a className={`contents`}>
              <div className={`p-[5px]`}>
                <div className={`h-[24px]`}>
                  <Image width={24} height={24} src={home} alt="home" />
                </div>
              </div>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const MobileHeader = (props: HeaderProps) => {
  const { type } = props;

  if (type === `invisible`) {
    return null;
  }

  if (type === `static`) {
    return <MobileStaticHeader {...props}></MobileStaticHeader>;
  }

  return <MobileFloatHeader {...props}></MobileFloatHeader>;
};

const TabletHeader = (props: HeaderProps) => {
  const { type } = props;

  if (type === `invisible`) {
    return null;
  }

  if (type === `static`) {
    return <MobileStaticHeader {...props}></MobileStaticHeader>;
  }

  return <TabletFloatHeader {...props}></TabletFloatHeader>;
};

const DesktopHeader = (props: HeaderProps) => {
  const { type } = props;
  const { authModel } = useAuth();

  if (type === `invisible`) {
    return null;
  }

  return (
    <div className="relative h-[72px]">
      <div className="absolute block h-[72px] w-full bg-gray-0 opacity-80 backdrop-blur-xl"></div>
      <div className="absolute flex h-[72px] w-full items-center justify-between p-[120px] py-5 px-[80px]">
        <Link href={`/`}>
          <a className={`contents`}>
            <Image width={109} height={32} src={logo} alt="logo" />
          </a>
        </Link>

        <div className={`flex items-center gap-[50px]`}>
          <Link href={`/search`}>
            <a className={`contents`}>
              <div className={`p-[5px]`}>
                <div className={`h-[24px]`}>
                  <Image
                    width={24}
                    height={24}
                    src={magnifier}
                    alt="magnifier"
                  />
                </div>
              </div>
            </a>
          </Link>

          {!authModel.auth0user && (
            <Link href={`/login`}>
              <a className={`contents`}>
                <p
                  className={`block font-roboto text-h5 font-bold not-italic text-gray-600`}
                >
                  LOG IN
                </p>
              </a>
            </Link>
          )}
          {authModel.auth0user && (
            <>
              <Link href={`/my-purchases`}>
                <a className={`contents`}>
                  <p
                    className={`block font-roboto text-h5 font-bold not-italic text-gray-600`}
                  >
                    MY TICKETS
                  </p>
                </a>
              </Link>
              <Link href={`/my-profile`}>
                <a className={`contents`}>
                  <p
                    className={`block font-roboto text-h5 font-bold not-italic text-gray-600`}
                  >
                    MY PROFILE
                  </p>
                </a>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const Header = (props: HeaderProps) => {
  const {} = props;
  const viewport = useViewport();

  if (viewport == `mobile`) {
    return <MobileHeader {...props} />;
  }
  if (viewport == `tablet`) {
    return <TabletHeader {...props} />;
  }
  if (viewport == `desktop`) {
    return <DesktopHeader {...props} />;
  }

  return null;
};

export default Header;
