import { ReactNode } from 'react';

import Footer from './Footer';
import Header, { HeaderType } from './Header';

import useViewport from '@/hooks/useViewport';

export type LayoutProps = {
  children?: ReactNode;
  overlay?: boolean;
  headerType: HeaderType;
};

const MobileLayout = (props: LayoutProps) => {
  const { children, overlay, headerType } = props;

  return (
    <div className={`relative min-h-screen w-full bg-cover bg-top`}>
      {overlay && (
        <div className="absolute inset-y-0 w-full bg-gray-0 opacity-70"></div>
      )}
      <div className={`relative flex min-h-screen w-full flex-col pb-[160px]`}>
        <div className={`fixed top-0 left-0 right-0 z-10 w-full bg-gray-0`}>
          <Header type={headerType}></Header>
        </div>
        <div className={`invisible w-full`}>
          <Header type={headerType}></Header>
        </div>
        {children}
      </div>
      <Footer />
    </div>
  );
};

const DesktopLayout = (props: LayoutProps) => {
  const { children, overlay, headerType } = props;

  return (
    <div
      className={`relative flex min-h-screen w-full flex-col justify-between bg-cover bg-top`}
    >
      {overlay && (
        <div className="absolute inset-y-0 w-full bg-gray-0 opacity-70"></div>
      )}
      <div className={`relative mb-[240px] flex w-full grow flex-col`}>
        <div className={`fixed top-0 left-0 right-0 z-[11] w-full bg-gray-0`}>
          <Header type={headerType}></Header>
        </div>
        <div className={`invisible w-full`}>
          <Header type={headerType}></Header>
        </div>
        {children}
      </div>
      <Footer />
    </div>
  );
};

const Layout = (props: LayoutProps) => {
  const {} = props;
  const viewport = useViewport();

  if (viewport == `mobile`) {
    return <MobileLayout {...props} />;
  }
  if (viewport == `tablet` || viewport == `desktop`) {
    return <DesktopLayout {...props} />;
  }

  return null;
};

export default Layout;
