import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';

import logoWhite from './assets/logo-white.svg';

import useViewport from '@/hooks/useViewport';

export type FooterProps = {};

export const FooterWrapper = styled.div`
  border-radius: 40px 40px 0 0;
`;

const MobileFooter = (props: FooterProps) => {
  const {} = props;

  return (
    <div className={`relative h-[382px]`}>
      <FooterWrapper
        className={`absolute bottom-0 top-0 flex w-full flex-col items-center border border-gray-10 bg-gray-0 pl-5 pt-10 pr-2 `}
      >
        <div className={`w-full max-w-screen-xl `}>
          <div className={`gap-[50px]`}>
            <Link href="/">
              <a>
                <Image
                  width={128}
                  height={38}
                  src={logoWhite}
                  alt="logo-white"
                />
              </a>
            </Link>
            <div
              className={`mt-[64px] mb-[56px] flex flex-col items-start gap-[32px] `}
            >
              <Link href="/terms">
                <a>
                  <p className={`font-roboto text-h5 text-gray-600`}>
                    Terms of Service
                  </p>
                </a>
              </Link>
              <Link href="/privacy">
                <a>
                  <p className={`font-roboto text-h5 text-gray-600`}>
                    Privacy Policy
                  </p>
                </a>
              </Link>
              <p className={`font-roboto text-h5 text-gray-600`}>
                <a href="https://support.someday.fm">Support</a>
              </p>
            </div>
          </div>

          <p className={`font-roboto text-c1 font-medium text-gray-100`}>
            Copyright © SOMEDAY. All rights reserved.
          </p>
        </div>
      </FooterWrapper>
    </div>
  );
};

const DesktopFooter = (props: FooterProps) => {
  const {} = props;

  return (
    <div className={`relative h-[200px]`}>
      <FooterWrapper
        className={`absolute bottom-0 top-0 flex h-[200px] w-full flex-col items-center border border-gray-10 bg-gray-0 pl-5 pt-10 pr-2`}
      >
        <div
          className={`flex w-full max-w-screen-xl flex-row items-center justify-between`}
        >
          <div
            className={`flex flex-row items-center justify-between gap-[50px]`}
          >
            <Link href="/">
              <a>
                <Image
                  width={128}
                  height={38}
                  src={logoWhite}
                  alt="logo-white"
                />
              </a>
            </Link>
            <div className={`mt-0 mb-0 flex flex-row items-start gap-[32px]`}>
              <Link href="/terms">
                <a>
                  <p className={`font-roboto text-h5 text-gray-600`}>
                    Terms of Service
                  </p>
                </a>
              </Link>
              <Link href="/privacy">
                <a>
                  <p className={`font-roboto text-h5 text-gray-600`}>
                    Privacy Policy
                  </p>
                </a>
              </Link>
              <p className={`font-roboto text-h5 text-gray-600`}>
                <a href="https://support.someday.fm">Support</a>
              </p>
            </div>
          </div>

          <p
            className={`text-right font-roboto text-c1 font-medium text-gray-100 `}
          >
            Copyright © SOMEDAY. All rights reserved.
          </p>
        </div>
      </FooterWrapper>
    </div>
  );
};

const Footer = (props: FooterProps) => {
  const {} = props;
  const viewport = useViewport();

  if (viewport == `mobile`) {
    return <MobileFooter {...props} />;
  }
  if (viewport == `tablet` || viewport == `desktop`) {
    return <DesktopFooter {...props} />;
  }

  return null;
};

export default Footer;
